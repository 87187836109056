const sizes = {
  xs: {
    w: "0.75rem",
    h: "0.75rem",
  },
  sm: {
    w: "1rem",
    h: "1rem",
  },
  md: {
    w: "1.5rem",
    h: "1.5rem",
  },
  lg: {
    w: "2rem",
    h: "2rem",
  },
  xl: {
    w: "3rem",
    h: "3rem",
  },
}

const defaultProps = {
  size: "md",
}

export default {
  sizes,
  defaultProps,
}
